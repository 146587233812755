import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import icon_ok from '../../assets/image/icon_ok.png'
import icon_cancel from '../../assets/image/icon_cancel.png'
import deleteIcon from '../../assets/image/delete_1.png'
import editIcon from '../../assets/image/edit_1.png'
import excelIcon from '../../assets/image/excel.png'
import refreshIcon from '../../assets/image/refresh.png'
import team_icon from '../../assets/image/team.png'
import { ToastContext } from '../../utils/contexts/context';
import { useNavigate } from 'react-router-dom';
import { deleteUser, getAllUsers, getUserById } from '../../utils/services/UserService';
import defaultImage from '../../assets/image/default.png'
import { getItem } from '../../utils/services/localStorage';
import Modal from '../../components/modal/modal'
import search_icon from '../../assets/image/search.png'
import icon_trash from '../../assets/image/trash.gif'
import dateFormat from 'dateformat';
import { generatePagination } from '../../utils';

function Teams() {

  // ***********  Pour gerer les authorizations  ***********
  const [privileges, setPrivileges] = useState([])

  const getUser = () =>{
    getUserById(getItem('userId'))
    .then((resp)=>{
      setPrivileges(resp.data.role.privileges)
    }).catch((error)=>{
      console.log(error)
    })
  }

  let onePrivilege ={}

  for(let privilege of privileges){
    if(privilege.menu === "Gestion utilisateurs"){
      onePrivilege = { 
                  'haveView' :privilege.haveView,
                  'haveAdd': privilege.haveAdd,
                  'haveEdit':privilege.haveEdit,
                  'haveDelete':privilege.haveDelete
                }
    }
  }

  useEffect(()=>{
    getUser();
  },[])

  const [toast, setToast] = useContext(ToastContext);

  const [keyWork, setKeyWork] = useState('');
  const [search, setSearch] = useState('');

  let navigate = useNavigate();

  // La liste des articles
  const column = ["ID", "", "Nom", "Prenom","nom utilisateur","Email","Telephone","Role", "Statut", "Date d'enregistrement","Action"];
  const [data, setData] = useState([])

  useEffect(()=>{
    handleGetUsers();
  },[setData])

  const handleGetUsers =()=>{
      getAllUsers()
          .then((resp)=> {
            setData(resp.data)
          })
          .catch((error)=>{
              console.log(error)
          })
  }

  const handleAdd = ()=>{
    if(onePrivilege.haveAdd===true){
      navigate('/addUser')
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
  }

  // ********** Confirmation de la suppression **********
  // Dialog
  const [dialog, setDialog] = useState({
    icon: '',
    title:'',
    message: '',
    isOpen: false
}) 

// Ce qu'on doit faire selon le choix de la Boite de dialog
const [currentId, setCurrentId] = useState(0);
const confirmeDiolog = (choose) =>{
    if(choose){
        confirmeDelete()
        setDialog('', '', false)
    }else{
        setDialog('', '', false)
    }
}

// 
const confirmeDelete = () =>{
  deleteUser(currentId)
      .then((resp)=>{
          if(resp.data.message){
            handleGetUsers()
            setToast({toast, isOpen:true, isSuccess: true, message: resp.data.message, icon:icon_ok})
          }else{
            setToast({toast, isOpen:true, isSuccess: false, message: "Suppression echouée", icon:icon_cancel})
          }
      }).catch((error)=>{
          console.log(error)
      })
}
// **************************************************

  const handleDelete = (id) =>{
    if(onePrivilege.haveDelete===true){
      setCurrentId(id)
      setDialog({
        icon: icon_trash,
        title:'Confirmation de suppression',
        message: 'Êtes-vous sûr(e) de vouloir supprimer?',
        isOpen:true,
      })
    }else{
      setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
    }
}

const handleUpdate = (id) =>{
  if(onePrivilege.haveEdit===true){
    navigate(`/updateUser/${id}`)
  }else{
    setToast({toast, isOpen:true, isSuccess: false, message: "Vous n'avez pas accès ici", icon:icon_cancel})
  }
}

const handleSearch = (e) =>{
  e.preventDefault()
  setSearch(keyWork)
}

  const refreshData = () =>{
    window.location.reload();
  }

  // Export to Excel
  const handleOnExcel=()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_utilisateurs.xlsx');
  }

    const [filtrage, setFiltrage]= useState('')
    
    const today= new Date();
    const [dateFiltre, setDateFiltre] = useState(dateFormat(today, "isoDate"));
    const dataOrder = data.filter(item =>dateFormat(item.dateVente, "isoDate")===dateFiltre);

    const [currentPage, setCurrentPage]= useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = dataOrder.slice(firstIndex, lastIndex)
    const npage = Math.ceil(dataOrder.length/recordsPerPage)
    const numbers = generatePagination(currentPage, npage)

    useEffect(()=>{
        setCurrentPage(1)
      }, [dateFiltre, filtrage, search, recordsPerPage])

    useEffect(()=>{
      if(onePrivilege.haveView===false){
        navigate('/')
      }
    },[privileges])

  return onePrivilege.haveView ? (
    <div className='contact_container'>
      <div className="header_container">
        <div className="div">
          <img src={team_icon} alt='icon'/>
          <h4>Utilisateurs</h4>
        </div>
        
        <h6>La liste des utilisateurs du système</h6>
      </div>


      <div className="list_contact">

        <div className="header_list">

            <div className="header_list_body">

                <div className="header_body_left">
                  {onePrivilege.haveAdd && <button className='header_body_left_button_add' onClick={()=> handleAdd()}>Ajouter</button>}
                  <button><img src={refreshIcon} alt='refresh' onClick={()=> refreshData()}/></button>
                  <button><img src={excelIcon} alt='excel' onClick={()=> handleOnExcel()}/></button>
                </div>
                <div className="header_body_right"></div>

            </div>

            <div className="header_list_body">
                <div className="header_body_left">
                    <h6>Montrer</h6>
                    <form>
                        <select name='recordsPerPage' onChange={(e)=> setRecordsPerPage(e.target.value)}>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                        </select>
                    </form>
                    <h6>Entrées</h6>
                </div>

                <div className="header_body_right">
                    <form onSubmit={handleSearch}>
                          <input
                              value={keyWork}
                              onChange={(e)=>setKeyWork(e.target.value)}
                              placeholder='Recherche'
                          />
                          <button>
                            <img src={search_icon} alt='search'/>
                          </button>
                    </form>
                </div>

            </div>
            
        </div>
        {/* End header list */}

        <div className="content_table">
          <table>
            <thead>
              <tr>       
                  {column.map((item, index)=>(
                    <th key={index}>{item}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {records.map((item, index)=>
              !search || search.toLowerCase() === item.nom.toLowerCase() 
              || search.toLowerCase() === item.prenom.toLowerCase()
              || search.toLowerCase() === item.role.nom.toLowerCase() 
              || search.toLowerCase() === item.username.toLowerCase()
              ?(
                <tr key={index}>
                    <td>{item.id}</td>
                    <td><img src={item.imageUrl=== "[object Object]" ? defaultImage : item.imageUrl} alt='cover'className='image_cover'/></td>
                    <td>{item.nom}</td>
                    <td>{item.prenom}</td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td>{item.tel}</td>
                    <td>{item.role.nom}</td>
                    <td>{item.statut === true ? "actif" : "inactif"}</td>
                    <td>{dateFormat(item.createdAt, "fullDate")}</td>
                    <td style={{display:'flex', gap: '5px'}}>
                      <img src={deleteIcon} alt='delete' className='delete_icon' onClick={()=>handleDelete(item.id)}/>
                      <img src={editIcon} alt='edit' className='update_icon' onClick={()=> handleUpdate(item.id)}/>
                    </td>
                </tr>
              ) : (<tr key={index}></tr>))}
            </tbody>
          </table>
        </div>

        <div className="content_pagination">
              <ul className='pagination'>
                  <li className='page_item'>
                      <button onClick={prePage} className='page_link'>prev</button>
                  </li>
                  <div className="list_page">
                      {
                          numbers.map((n,i)=>(
                              <li key={i} className='page_item'>
                                  <button onClick={()=>changeCPage(n)} className={currentPage===n ? 'active' : 'page_link'}>{n}</button>
                              </li>
                          ))
                      }
                  </div>
                  <li className='page_item'>
                      <button onClick={nextPage} className='page_link'>next</button>
                  </li>
              </ul>
        </div>

        {/* Modal de confirmation de suppression */}
        <Modal onDialog={confirmeDiolog} dialog={dialog} setDialog={setDialog}/>
      </div>
    </div>
  ) : (
    <></>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    if(id!=='...')
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default Teams