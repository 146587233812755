import React, { useEffect, useState } from 'react'
import './dashboard.css'
import pos from '../../assets/image/pos_icon.png'
import dish from '../../assets/image/icon_product.png'
import cash from '../../assets/image/cash.png'
import Countup from '../../components/countup/countup'
import { useNavigate } from 'react-router-dom';
import { getBestArticles } from '../../utils/services/articleService'
import { getAllOrders } from '../../utils/services/orderService'
import { getAllExpenses } from '../../utils/services/expenseServices'
import dateFormat from 'dateformat';
import defaultIcon from '../../assets/image/default.png';

function Dashboard() {
  const [searchOrder, setSearchOrder] = useState('')
  const [searchPayment, setSearchPayment] = useState('')

  const [articles, setArticles] = useState([])
  const [orders, setOrders] = useState([])
  const [expenses, setExpenses]=useState([])

  useEffect(()=>{
    handleGetProducts();
    handleGetOrders();
    handleGetExpenses();
  },[])

  const handleGetProducts = ()=>{
    getBestArticles()
        .then((resp)=> {
          setArticles(resp.data)
        })
        .catch((error)=>{
            console.log(error)
        })
}
const handleGetOrders =()=>{
    getAllOrders()
        .then((resp)=> {
            setOrders(resp.data)
        })
        .catch((error)=>{
            console.log(error)
        })
}

const handleGetExpenses =()=>{
  getAllExpenses()
      .then((resp)=> {
          setExpenses(resp.data)
      })
      .catch((error)=>{
          console.log(error)
      })
}

// ************* Pour calculer le montant en caisse**************
const today= new Date();
let dateFiltre = dateFormat(today, "isoDate")

let totalEnCaisse = 0;
let monnaie =0;
let nbreOrder = 0;
for (let i of orders) {
    if(dateFiltre===dateFormat(i.dateVente, "isoDate")){
      totalEnCaisse =totalEnCaisse + (i.montantVerser);
      monnaie = monnaie + (i.monnaie)
      nbreOrder+=1;
    }
}

// ****************************
const ordersFiltre = orders.slice(0, 10);
const expensesFiltre = expenses.slice(0, 10);
const articlesFiltre = articles.slice(0, 7);
  
  const navigate = useNavigate();
  return (
    <div className='dashboard'>
      
      <div className='cards_dashboard'>
        
        {/* Card_1 */}
        <div className="card_1">

          <div className='top_card'>
            <h5 style={{color : '#fff'}}>Commande effectuée</h5>
            <div className="icon_card" style={{backgroundColor: '#fff'}}>
              <img src={pos} alt='' />
            </div>
          </div>

          <div className='bottom_card'>
            <h4 style={{color : '#fff'}}><Countup end={nbreOrder}/></h4>
            <h6 style={{color : '#fff'}}>Commande pour la journée</h6>
          </div>

        </div>
        
        {/* Card_2 */}
        <div className="card_2">
            <div className='top_card'>
              <h5>Nombre Articles</h5>
              <div className="icon_card" style={{backgroundColor: '#E7F1F1'}}>
                <img src={dish} alt='' />
              </div>
            </div>

            <div className='bottom_card'>
              <h4><Countup end={articles.length}/></h4>
              <h6>Mise a jour a chaque nouvel article</h6>
            </div>
        </div>
        
        {/* Card_3 */}
        <div className="card_3">
            <div className='top_card'>
                <h5>Caisse en $HT</h5>
                <div className="icon_card" style={{backgroundColor: '#FFF1CC'}}>
                  <img src={cash} alt='' />
                </div>
            </div>

            <div className='bottom_card'>
                <h4><Countup end={totalEnCaisse-monnaie}/></h4>
                <h6>Montant en caisse de la journée</h6>
            </div>
        </div>
        
        {/* Card_4 */}
        <div className="card_4">
          
          <div className="shortcut_orders">
              <button onClick={()=>navigate('/pos')}> Passer un nouveau commande</button>
          </div>
          
          <div className="popular_dishes">
              <div className="head_popular_dishes">
                <h5>Articles Populaires</h5>
                <h6 onClick={()=> navigate('/articles')}>Voir plus</h6> 
              </div>
              <div className="table_popular_dishes">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articlesFiltre.map((item, index)=>
                      item.orders.length>0
                      ?(<tr key={index}>
                          <td>{item.id}</td>
                          <td>
                            <div className="card_popular_dishes">
                              <img src={item.imageUrl==="" ? item.imageUrl : defaultIcon} alt='card_dishes' className='image_card_popular_dishes'/>
                              <div className="about_card_popular_dishes">
                                <h5>{item.nomArticle}</h5>
                                <h6>Commandes : <span>{item.orders.length}</span></h6>
                              </div>
                            </div>
                          </td>
                      </tr>):(<tr key={index}></tr>)
                    )}
                  </tbody>
                </table>
              </div>
          </div>
          
          <div className="overview_expenses">
                <div className="head_overview_expenses">
                  <h5>Apercu des depenses</h5>
                  <h6 onClick={()=> navigate('/expenses')}>Voir plus</h6> 
                </div>
                {expensesFiltre.map((item, index)=>             
                  <div className="list_overview_expenses" key={index}>
                    <h5>{item.spender}</h5>
                    <h6>Date : <span>{dateFormat(item.createdAt, "isoDate")}</span></h6>
                  </div>
                )}
          </div>

        </div>

        {/* Card_5 */}
        <div className="card_5">
          <div className="overview_orders">
              <h5>Commandes</h5>

              <div className="search_overview">
                <form>
                    <input
                        value={searchOrder}
                        onChange={(e)=>setSearchOrder((e.target.value))}
                        placeholder='Recherche'
                    />
                </form>
              </div>

              <div className="overview_list_orders_payment">

                  {ordersFiltre.map((item, index)=>
                    
                    (!searchOrder || searchOrder === item.noFacture )
                    && item.montantDue === 0 && dateFiltre===dateFormat(item.dateVente, "isoDate")
                    ?(<div className="overview_item_orders_payment" key={index}>

                        <div className="overview_item_orders_payment_left">
                          <div className="overview_item_orders_payment_id">
                              <h5>{item.id}</h5>
                          </div>
                          <div className="overview_item_orders_payment_about">
                              <h5>No Facture: {item.noFacture}</h5>
                              <h6>{item.items} items</h6>
                          </div>
                        </div>

                        <div className="overview_item_orders_payment_right">
                          <div className="overview_item_orders_payment_right_statut">
                              <h6>{item.statutPayment}</h6>
                          </div>
                          <div className="overview_item_orders_payment_info">
                              <h6>Mtt: {item.montantVendu} gdes</h6>
                          </div>
                        </div>
                    </div>):(<p key={index}></p>)
                  )}
                  
              </div>
              

          </div>

          <div className="overview_payment">
              
              <h5>Paiements</h5>
              
              <div className="search_overview">
                <form>
                      <input
                          value={searchPayment}
                          onChange={(e)=>setSearchPayment((e.target.value))}
                          placeholder='Recherche'
                      />
                  </form>
              </div>

              <div className="overview_list_orders_payment">

                  {ordersFiltre.map((item,index)=>
                    
                    (!searchPayment || searchPayment === item.noFacture )
                    && item.montantDue > 0 && dateFiltre===dateFormat(item.dateVente, "isoDate")
                    ?(<div className="overview_item_orders_payment" key={index}>
                      
                      <div className="overview_item_orders_payment_left">
                            <div className="overview_item_orders_payment_id">
                                <h5>{item.id}</h5>
                            </div>
                            <div className="overview_item_orders_payment_about">
                                <h5>Type Vente: {item.statutPayment}</h5>
                                <h6>Orders {item.noFacture}</h6>
                            </div>
                      </div>
                      
                      <div className="overview_item_payment_right">
                        <button onClick={()=> navigate('/orders')}>Payé</button>
                      </div>

                    </div>):(<p key={index}></p>)
                  )}
                  
              </div>
              

          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Dashboard